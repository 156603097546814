<template>
  <div class="page">
    <div class="page-header">
      <el-button type="primary" icon="el-icon-plus iconfont icon-scan" v-if="isLAN" @click="scanItem">{{
        $t('device.scanDevice')
      }}</el-button>
      <el-button type="primary" icon="el-icon-plus" @click="addItem" v-has="'add'">{{
        $t('device.addDevice')
      }}</el-button>
      <el-button
        type="primary"
        plain
        icon="el-icon-plus iconfont icon-import"
        @click="importItem"
        v-has="'import'"
      >
        {{ $t('device.importDevice') }}</el-button
      >
      <el-button
        type="primary"
        icon="el-icon-upload2 iconfont icon-export"
        plain
        @click="exportItem"
        v-has="'export'"
        >{{ $t('device.exportDevice') }}</el-button
      >
      <el-button
        type="primary"
        icon="el-icon-upload2 iconfont icon-refresh"
        plain
        @click="refresh"
        >{{ $t('device.refresh') }}</el-button
      >
      <!-- v-has="'refresh'" -->
    </div>
    <div class="page-main">
      <el-form :inline="true" :model="tabelSearch">
        <div class="page-tools">
          <el-form-item>
            <el-button
              type="primary"
              icon="el-icon-upload2 iconfont icon-upgrade"
              @click="upgradeFirmwareItem()"
              :disabled="!tableSelection.length"
              v-has="'upgrade'"
              >{{ $t('device.upgradeFirmware') }}
            </el-button>
            <el-button
              type="primary"
              icon="el-icon-upload2 iconfont icon-ssite"
              @click="siteAssignmentItem()"
              :disabled="!tableSelection.length"
              v-has="'assignSite'"
              >{{ $t('device.siteAssignment') }}
            </el-button>
            <el-dropdown @command="allMoreCommand" style="margin-left: 10px;">
              <el-button type="primary" :disabled="!tableSelection.length">
                {{ $t('more') }}<i class="el-icon-arrow-down el-icon--right"></i>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="deleteItem" v-has="'delete'">
                  {{ $t('delete') }}
                </el-dropdown-item>
                <el-dropdown-item command="rebootDeviceItem" v-has="'reset'">
                  {{ $t('device.rebootDevice') }}
                </el-dropdown-item>
                <el-dropdown-item command="factoryResetItem" v-has="'factory'">
                  {{ $t('device.factoryReset') }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </el-form-item>
          <el-form-item class="page-tools-right" v-has="'list'">
            <el-button type="text" class="btn-p0" @click="filter = !filter">
              {{ $t('filter') }}
              <i :class="filter ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"></i>
            </el-button>
          </el-form-item>
          <el-form-item class="page-tools-right keywords" v-has="'list'">
            <el-input
              v-model="tabelSearch.keywords"
              :placeholder="$t('device.keywordsPla')"
              prefix-icon="el-icon-search"
              clearable
              @change="getTabelData"
              style="width: 304px;"
            ></el-input>
          </el-form-item>
          <el-form-item class="page-tools-right" v-has="'list'">
            <select-model
              v-model="tabelSearch.model"
              selectAll
              @change="getTabelData"
            ></select-model>
          </el-form-item>
        </div>
        <div class="page-tools" v-show="filter" v-has="'list'">
          <el-form-item class="page-tools-right">
            <select-firmware
              v-model="tabelSearch.firmware"
              :show="false"
              selectAll
              subLabel
              @change="getTabelData"
              :data.sync="firmwareList"
            ></select-firmware>
          </el-form-item>
          <el-form-item class="page-tools-right">
            <dist-select
              v-model="tabelSearch.accountStatus"
              :optionData="deviceAccountStatus"
              :selectAll="{ label: $t('device.allAccountStatus'), value: null }"
              @change="getTabelData"
            ></dist-select>
          </el-form-item>
          <el-form-item class="page-tools-right">
            <dist-select
              v-model="tabelSearch.deviceStatus"
              :optionData="deviceStatus"
              :selectAll="{ label: $t('device.allDeviceStatus'), value: null }"
              @change="getTabelData"
            ></dist-select>
          </el-form-item>
          <!-- EDM 第一阶段需隐藏模块 <el-form-item class="page-tools-right">
            <select-city
              v-model="tabelSearch.city"
              selectAll
              @change="getTabelData"
            ></select-city>
          </el-form-item> -->
        </div>
      </el-form>
      <table-pagination
        memory="device"
        ref="refTable"
        :max-height="pageTabelHeight"
        :tableData="tableData"
        :columnData="columnData"
        selectionShow
        @selection-change="selectionChange"
        optionShow
        columnOptionShow
        :optionMinWidth="160"
        :total="tabelTotal"
        :currentPage.sync="tabelSearch.currentPage"
        :pageSize.sync="tabelSearch.pageSize"
        @changeCurrentPage="getTabelData(false)"
        @sort-change="tabelSortChange"
      >
        <!-- macAddress -->
        <template slot="macAddress" slot-scope="scope">
          <el-tooltip
            class="item"
            effect="dark"
            :content="deviceStatus[scope.row.status]"
            placement="top"
          >
            <i
              class="stateDot"
              :class="
                scope.row.status == 1
                  ? 'bg-success'
                  : scope.row.status == 2
                  ? 'bg-danger'
                  : 'bg-info'
              "
            ></i>
          </el-tooltip>
          {{ scope.row.macAddress.toUpperCase() }}
          <i v-if="scope.row.pushStatus != 1" class="el-icon-circle-close"></i>
          <el-tooltip
            v-if="scope.row.processingTask > 0 && scope.row.pushStatus == 1"
            effect="dark"
            :content="$t('task.task')"
            placement="top"
            :open-delay="1000"
          >
            <el-button
              type="text"
              class="btn-p0"
              @click="$router.push({ path: '/task', query: { macAddress: scope.row.macAddress } })"
            >
              <i class="el-icon-alarm-clock"></i>
            </el-button>
          </el-tooltip>
        </template>
        <!-- accountStatus -->
        <template slot="accountStatus" slot-scope="scope">
          <DeviceStatus :account-list="scope.row.accountList" :refresh-switcher="refreshSwitcher" :device-status="scope.row.status"/>
        </template>
        <template slot="publicIp" slot-scope="scope">
          <a :href="'https://'+scope.row.publicIp" target="_blank">{{scope.row.publicIp}}</a>
        </template>
         <template slot="privateIp" slot-scope="scope">
          <a :href="'https://'+scope.row.privateIp" target="_blank">{{scope.row.privateIp}}</a>
        </template>
        <!-- option -->
        <template slot="option" slot-scope="scope">
          <el-tooltip
            effect="dark"
            :content="$t('accountConfiguration')"
            placement="top"
            :open-delay="1000"
            v-has="'assignAccount'"
          >
            <el-button
              type="text"
              icon="el-icon-user iconfont icon-user"
              class="btn-p0"
              @click="accountConfigurationItem(scope.row)"
            ></el-button>
          </el-tooltip>
          <el-tooltip
            effect="dark"
            :content="$t('setParameters')"
            placement="top"
            :open-delay="1000"
            v-has="'paramSet'"
          >
            <el-button
              type="text"
              icon="el-icon-setting iconfont icon-setting"
              class="btn-p0"
              @click="settingItem(scope.row)"
            ></el-button>
          </el-tooltip>
          <el-tooltip effect="dark" :content="$t('dialogDiagnostics')" placement="top" :open-delay="1000">
            <el-button type="text" class="btn-p0" @click="goToDiagnositcs(scope.row)" :disabled=" (scope.row.status != 1) || (scope.row.deviceModel == 'H2') || (scope.row.deviceModel == 'H2P')">
              <i class="el-icon-first-aid-kit iconfont icon-strategy"></i>
            </el-button>
          </el-tooltip>
          <el-dropdown @command="moreCommand" style="margin-left: 10px;">
            <el-button
              type="text"
              class="btn-p0"
              icon="el-icon-more iconfont icon-more"
            ></el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item :command="{ index: 1, row: scope.row }" v-has="'deviceDetail'">
                <i class="el-icon-warning-outline"></i>{{ $t('device.deviceDetails') }}
              </el-dropdown-item>
              <el-dropdown-item :command="{ index: 2, row: scope.row }" v-has="'optLog'">
                <i class="el-icon-date"></i>{{ $t('device.operationLogs') }}
              </el-dropdown-item>
              <el-dropdown-item :command="{ index: 3, row: scope.row }" v-has="'taskLog'">
                <i class="el-icon-time"></i>{{ $t('device.taskHistory') }}
              </el-dropdown-item>
              <el-dropdown-item divided></el-dropdown-item>
              <el-dropdown-item :command="{ index: 4, row: scope.row }" v-has="'edit'">
                <i class="el-icon-edit iconfont icon-edit"></i>{{ $t('device.editDevice') }}
              </el-dropdown-item>
              <el-dropdown-item :command="{ index: 6, row: scope.row }" v-has="'pushConfig'">
                <i class="el-icon-s-promotion iconfont icon-push"></i>
                {{
                  scope.row.pushStatus
                    ? $t('device.disabledPushConfiguration')
                    : $t('device.enablePushConfiguration')
                }}
              </el-dropdown-item>
              <el-dropdown-item divided></el-dropdown-item>
              <el-dropdown-item :command="{ index: 8, row: scope.row }" v-has="'reset'">
                <i class="el-icon-refresh"></i>{{ $t('device.rebootDevice') }}
              </el-dropdown-item>
              <el-dropdown-item :command="{ index: 9, row: scope.row }" v-has="'factory'">
                <i class="el-icon-refresh-right"></i>{{ $t('device.factoryReset') }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </table-pagination>
    </div>
    <!-- 导入 -->
    <dialog-import-item
      :visible.sync="importVisible"
      url="/device/import"
      :title="$t('device.importDevice')"
      @submit="reloadSite"
      generalTemplateBtnShow
      :generalTemplateBtnText="$t('device.deviceTemplate')"
      @generalTemplateclick="generalTemplateclick"
      msgTableShow
      :msgTable="importItemMsgTable"
      @msgTableExport="importMsgTableExport"
      @importSuccess="getTabelData"
      @close="importVisible = false"
    ></dialog-import-item>
    <!-- 升级固件 -->
    <dialog-upgrade-firmware
      v-if="upgradeFirmwareVisible"
      :visible.sync="upgradeFirmwareVisible"
      :req="upgradeFirmwareReq"
    ></dialog-upgrade-firmware>
    <!-- 分配站点 -->
    <dialog-site-assignment
      v-if="siteAssignmentVisible"
      :visible.sync="siteAssignmentVisible"
      :req="siteAssignmentReq"
      @change="getTabelData"
    ></dialog-site-assignment>
    <!-- 重启设备 -->
    <dialog-task-execution-mode
      v-if="rebootDeviceVisible"
      :title="rebootDeviceTitle"
      :visible.sync="rebootDeviceVisible"
      :req="rebootDeviceReq"
      @submit="taskExecutionModeSubmit"
    ></dialog-task-execution-mode>
    <!-- 恢复出厂 -->
    <dialog-task-execution-mode
      v-if="factoryResetVisible"
      :title="factoryResetTitle"
      :visible.sync="factoryResetVisible"
      :req="factoryResetReq"
      @submit="taskExecutionModeSubmit"
    ></dialog-task-execution-mode>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import {
  TablePagination,
  SelectModel,
  SelectFirmware,
  // SelectCity,
  DistSelect,
  DialogImportItem,
  DialogTaskExecutionMode,
} from '@/components'
import {
  deviceSearch,
  deviceExport,
  deviceTemplateExport,
  removeDeviceBatch,
  deviceAccoutnStatus,
  deviceDisablePushConfig,
  deviceEnablePushConfig,
  deviceReboot,
  deviceFactoryReset,
  deviceExportFailedData,
} from '@/api/device'
import { downloadFile, pushSuccessMassage } from '@/plugins/methods'
import DialogUpgradeFirmware from './dialog-upgrade-firmware'
import DialogSiteAssignment from './dialog-site-assignment'
import mixinTable from '@/components/mixin/table'
import DeviceStatus from '@/views/Device/DeviceStatus'

export default {
  name: 'Device',
  mixins: [mixinTable],
  components: {
    DeviceStatus,
    TablePagination,
    SelectModel,
    SelectFirmware,
    // SelectCity,
    DistSelect,
    DialogImportItem,
    DialogUpgradeFirmware,
    DialogSiteAssignment,
    DialogTaskExecutionMode,
  },
  data() {
    return {
      refreshSwitcher: false,
      filter: false,
      tabelSearch: {
        currentPage: 1,
        pageSize: 10,
        sortField: 'updateTime',
        sortOrder: 'desc',
        keywords: null,
        accountStatus: null,
        deviceStatus: null,
        firmware: null,
        model: null,
        city: null,
        site: []
      },
      tabelTotal: 0,
      columnData: [
        {
          label: this.$t('device.macAddress'),
          prop: 'macAddress',
          minWidth: 200,
          sortable: 'custom',
          slot: true,
        },
        {
          label: this.$t('device.snCode'),
          prop: 'snCode',
          minWidth: 160,
          sortable: 'custom',
        },
        {
          label: this.$t('device.deviceName'),
          prop: 'deviceName',
          minWidth: 150,
        },
        {
          label: this.$t('device.deviceModel'),
          prop: 'deviceModel',
          minWidth: 180,
          sortable: 'custom',
        },
        // EDM 第一阶段需隐藏模块
        // {
        //   label: this.$t('device.city'),
        //   prop: 'city',
        //   minWidth: 150,
        // },
        {
          label: this.$t('device.firmwareVersion'),
          prop: 'firmware',
          minWidth: 220,
          sortable: 'custom',
        },
        {
          label: this.$t('device.publicIp'),
          prop: 'publicIp',
          minWidth: 160,
          sortable: 'custom',
          slot: true,
        },
        {
          label: this.$t('device.privateIp'),
          prop: 'privateIp',
          minWidth: 160,
          sortable: 'custom',
          slot: true,
        },

        {
          label: this.$t('device.accountStatus'),
          prop: 'accountStatus',
          minWidth: 180,
          sortable: 'custom',
          slot: true,
          placeholderDisable: true,
        },
        {
          label: this.$t('device.siteName'),
          prop: 'siteName',
          minWidth: 150,
          sortable: 'custom',
        },
        {
          label: this.$t('dateModified'),
          prop: 'updateTime',
          minWidth: 200,
          sortable: 'custom',
          filter: 'dateFormat',
        },
      ],
      tableData: [],
      columnData2: [
        {
          label: this.$t('device.account'),
          prop: 'accountNo',
          minWidth: 100,
          filter: (val) => this.$t('device.account') + val,
        },
        {
          label: this.$t('sipAccount.userName'),
          prop: 'sipUserId',
          minWidth: 100,
        },
        {
          label: this.$t('sipAccount.sipServer'),
          prop: 'sipServer',
          minWidth: 100,
          filter: (val, row) => `${row.serverName}(${val})`,
        },
        {
          label: this.$t('device.accountStatus'),
          prop: 'accountStatus',
          minWidth: 100,
          filter: (val) => this.deviceAccountStatus2[val],
          placeholderDisable: true,
        },
      ],
      tableData2: [],
      firmwareList: [],
      // 导入
      importVisible: false,
      // 升级固件
      upgradeFirmwareVisible: false,
      upgradeFirmwareReq: {
        selectList: [],
      },
      // 分配站点
      siteAssignmentVisible: false,
      siteAssignmentReq: {
        selectList: [],
      },
      // 重启设备
      rebootDeviceVisible: false,
      rebootDeviceTitle: this.$t('device.rebootDevice'),
      rebootDeviceReq: {
        subtitle: this.$t('device.rebootDeviceTip'),
        api: 'deviceReboot',
        selectList: [],
      },
      // 恢复出厂
      factoryResetVisible: false,
      factoryResetTitle: this.$t('device.factoryReset'),
      factoryResetReq: {
        subtitle: this.$t('device.factoryResetTip'),
        api: 'deviceFactoryReset',
        selectList: [],
      },
    }
  },
  computed: {
    ...mapState('session', ['isLAN']),
    ...mapState('memory', ['language']),
    ...mapGetters('dist', ['deviceAccountStatus', 'deviceAccountStatus2', 'deviceStatus']),
    ...mapGetters('session', ['pageTabelHeight']),
    importItemMsgTable() {
      return {
        title: this.$t('device.importDevice'),
        columnData: [
          {
            label: this.$t('device.macAddress'),
            prop: 'macAddress',
            minWidth: 100,
            filter: 'toUpperCase',
          },
          {
            label: this.$t('device.deviceName'),
            prop: 'deviceName',
            minWidth: 100,
          },
          {
            label: this.$t('failedReason'),
            prop: this.language === 'en' ? 'reasonEn' : 'reason',
            minWidth: 200,
          },
        ]
      }
    },
    tableSelectionIds() {
      return this.tableSelection.map((e) => e.id)
    },
  },
  created() {
    if (this.$route.query.deviceStatus) {
      this.tabelSearch.deviceStatus = this.$route.query.deviceStatus
      this.filter = true
    }
    if (this.$route.query.model) this.tabelSearch.model = this.$route.query.model
    this.tableInit()
  },
  methods: {
    // 获取数据
    getTabelData(resetPageNumber = true) {
      console.log("getTabelData")
      resetPageNumber && (this.tabelSearch.currentPage = 1)
      deviceSearch(this.tabelSearch)
        .then(({ data }) => {
          this.tableData = data.result.rows[0].list // 表格数据
          this.tabelTotal = data.result.rows[0].total // 表格数据条目
          this.$refs.refTable.clearFilter() // 表格过滤清空
          this.refreshSwitcher = !this.refreshSwitcher
        })
        .catch(() => {})
    },
    // 获取账号数据
    getAccountData(id) {
      this.tableData2 = []
      deviceAccoutnStatus({ id })
        .then(({ data }) => {
          this.tableData2 = data.result.rows
        })
        .catch(() => {})
    },
    // 重载页面
    reloadSite() {
      this.$store.commit('session/SET_SIDEBAR_UPDATE', true)
      this.refresh()
    },
    // 增
    addItem() {
      this.$router.push('/device/addDevice')
    },
    // 导出
    exportItem() {
      deviceExport(this.tabelSearch).then((res) => {
        downloadFile(res)
      })
    },
    // 导入
    importItem() {
      this.importVisible = true
    },
    // 导入-导出错误信息
    importMsgTableExport(val) {
      deviceExportFailedData(val).then((res) => {
        downloadFile(res)
      })
      this.importVisible = false
    },
    // 导入-导出模板
    generalTemplateclick() {
      deviceTemplateExport().then((res) => {
        downloadFile(res)
      })
    },
    // 刷新
    refresh() {
      console.log('refresh...')
      this.tabelSearch = {
        currentPage: 1,
        pageSize: 10,
        sortField: 'updateTime',
        sortOrder: 'desc',
        keywords: null,
        accountStatus: null,
        deviceStatus: null,
        firmware: null,
        model: null,
        city: null,
        site: this.tabelSearch.site
      }
      this.tableInit()
    },
    // 多选更多
    allMoreCommand(command) {
      this[command]()
    },
    // 更多
    moreCommand(command) {
      switch (command.index) {
        case 1: // Device Details
          this.$router.push({
            path: '/device/deviceDetailInfo',
            query: {
              id: command.row.id,
              subtitle: command.row.macAddress,
            },
          })
          break

        case 2: // Operation Logs
          this.$router.push({
            path: '/device/deviceOperationLog',
            query: {
              id: command.row.id,
              subtitle: command.row.macAddress,
            },
          })
          break

        case 3: // Task History
          this.$router.push({
            path: '/task',
            query: { macAddress: command.row.macAddress },
          })
          break
        case 4: // Edit Device
          this.$router.push({
            path: '/device/editDevice',
            query: {
              id: command.row.id,
              model: command.row.deviceModel,
            },
          })
          break

        case 6: // Disabled Push Configuration、Enable Push Configuration
          this.pushConfigurationItem(command.row)
          break

        case 8: // Reboot Device
          this.rebootDeviceItem(command.row.id)
          break

        case 9: // Factory Reset
          this.factoryResetItem(command.row.id)
          break

        default:
          console.log(this.moreBtn[command.index].label)
          break
      }
    },
    // 删
    deleteItem() {
      this.$confirm(this.$t('device.deleteTip'), this.$t('tips'), {
        confirmButtonText: this.$t('confirm'),
        cancelButtonText: this.$t('cancel'),
        type: 'warning',
      })
        .then(() => {
          removeDeviceBatch({
            ids: this.tableSelectionIds,
            args: this.tableSelection.map((e) => e.macAddress),
          })
            .then((res) => {
              this.$message.success(res.data.message)
              this.getTabelData()
            })
            .catch(() => {})
        })
        .catch(() => {})
    },
    // 升级固件
    upgradeFirmwareItem() {
      this.upgradeFirmwareVisible = true
      this.upgradeFirmwareReq.selectList = this.tableSelection
      this.upgradeFirmwareReq.firmwareList = this.firmwareList
    },
    // 分配站点
    siteAssignmentItem() {
      this.siteAssignmentVisible = true
      this.siteAssignmentReq.selectList = this.tableSelectionIds
    },
    // 推送配置
    pushConfigurationItem({ pushStatus, id }) {
      const api = pushStatus ? deviceDisablePushConfig : deviceEnablePushConfig
      api({ id })
        .then(({ data }) => {
          this.$message.success(data.message)
          this.getTabelData()
        })
        .catch(() => {})
    },
    // 重启设备
    rebootDeviceItem(id) {
      this.rebootDeviceVisible = true
      this.rebootDeviceReq.selectList = id ? [id] : this.tableSelectionIds
    },
    // 恢复出厂设置
    factoryResetItem(id) {
      this.factoryResetVisible = true
      this.factoryResetReq.selectList = id ? [id] : this.tableSelectionIds
    },
    // 账号配置
    accountConfigurationItem({ id, pushStatus, site, macAddress, deviceName }) {
      this.$router.push({
        path: '/device/deviceAccount',
        query: { id, pushStatus, siteId: site, subtitle: `${deviceName} ${macAddress}` },
      })
    },
    // 设置
    settingItem({ id, pushStatus, macAddress, deviceName, deviceModel }) {
      this.$router.push({
        path: '/device/setParam',
        query: {
          relationName: 'device',
          id,
          pushStatus,
          subtitle: `${deviceName} ${macAddress}`,
          deviceModel: deviceModel,
        },
      })
    },
    goToDiagnositcs({ macAddress}){
      this.$router.push({
        path: '/diagnostics',
        query: {
          mac: macAddress,
        },
      })
    },
    // 重启设备、恢复出厂
    taskExecutionModeSubmit(params, req) {
      const dist = {
        deviceReboot: {
          api: deviceReboot,
          visible: 'rebootDeviceVisible',
        },
        deviceFactoryReset: {
          api: deviceFactoryReset,
          visible: 'factoryResetVisible',
        },
      }
      dist[req.api]
        .api(params)
        .then(() => {
          pushSuccessMassage(this)
          this[dist[req.api].visible] = false
        })
        .catch(() => {})
    },
    // 扫描
    scanItem() {
      this.$router.push('/device/scanDevice')
    }
  },
}
</script>

<style lang="scss" scoped>
.account-status-tip {
  padding-top: 10px;
  text-align: center;
}
</style>
